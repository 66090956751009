import { useEffect } from 'react';

const loadCSS = (href) => {
  const link = document.createElement('link');
  link.rel = 'preload';
  link.href = href;
  link.as = 'style';
  link.onload = function() {
    this.onload = null;
    this.rel = 'stylesheet';
  };
  document.head.appendChild(link);

  const noscript = document.createElement('noscript');
  const noscriptLink = document.createElement('link');
  noscriptLink.rel = 'stylesheet';
  noscriptLink.href = href;
  noscript.appendChild(noscriptLink);
  document.head.appendChild(noscript);
};

const useLoadCSS = (path) => {
  useEffect(() => {
    if (path.includes('/menu')) {
      loadCSS('/menu.css');
    } else if (path.includes('/home')) {
      loadCSS('/home.css');
    } else if (path.includes('/footer')) {
      loadCSS('/footer.css');
    } else if (path.includes('/about')) {
      loadCSS('/about.css');
    } else if (path.includes('/price')) {
      loadCSS('/price.css');
    } else if (path.includes('/landing')) {
      loadCSS('/landing.css');
    } else if (path.includes('/contact')) {
      loadCSS('/contact.css');
    } else if (path.includes('/request')) {
      loadCSS('/request.css');
    }
  }, [path]);
};

export default useLoadCSS;
