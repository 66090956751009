import React, { lazy, Suspense } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import useLoadCSS from './components/home/useLoadCSS.js';

// Lazy load components
const Landing = lazy(() => import('./components/home/landing.js'));
const Home = lazy(() => import('./components/home/home.js'));
const Menu = lazy(() => import('./components/home/menu.js'));
const Chatbot = lazy(() => import('./components/home/whatsappbot.js'));
const About = lazy(() => import('./components/home/about.js'));
const Footer = lazy(() => import('./components/home/footer.js'));
const Price = lazy(() => import('./components/home/price.js'));
const Request = lazy(() => import('./components/home/request.js'));
const Contact = lazy(() => import('./components/home/contact.js'));

const LoadCSS = () => {
  const location = useLocation();
  useLoadCSS(location.pathname);
  return null;
};

function App() {
  return (
    <div className='bg-body-img'>
      <Router>
        <LoadCSS/>
        <Suspense fallback={<div></div>}>
          <Menu />
          <Routes>
            <Route path='/' element={<Landing />} />
            <Route path='/home' element={<Home />} />
            <Route path='/chatbot' element={<Chatbot />} />
            <Route path='/about' element={<About />} />
            <Route path='/price' element={<Price />} />
            <Route path='/request' element={<Request />} />
            <Route path='/contact' element={<Contact />} />
          </Routes>
          <Footer />
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
